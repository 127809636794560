<div class="container hero-cards-container">
  <div class="wrapper hero-cards-wrapper">
    <div class="headline">
      <h2 class="headline_left headline_white">
        Digitales Marketing – Köder für Talente und Kunden.
      </h2>
    </div>
    <div class="hero-card-container">
      <!--  -->
      <div *ngIf="isNextTo" class="hero-card" data-aos="fade-up" data-aos-delay="100" data-aos-duration="600">
        <h3>ZIELGRUPPEN <br> GENAU TREFFEN</h3>
        <p>
          Über passende Inhalte sprechen wir Ihre Zielgruppe direkt an. Mit verschiedenen Formaten sorgen wir für interessante Anzeigen.
        </p>
      </div>
      <div *ngIf="!isNextTo" class="hero-card" data-aos="fade-up-left" data-aos-delay="100" data-aos-duration="600">
        <h3>ZIELGRUPPEN <br> GENAU TREFFEN</h3>
        <p>
          Über passende Inhalte sprechen wir Ihre Zielgruppe direkt an. Mit verschiedenen Formaten sorgen wir für interessante Anzeigen.
        </p>
      </div>
      <div *ngIf="isNextTo" class="hero-card" data-aos="fade-up" data-aos-delay="300" data-aos-duration="600">
        <h3>IMMER IM <br> EINSATZ</h3>
        <p>
          Der Algorithmus weiß wann Ihre Zielgruppe aktiv ist. Er versucht die bestmöglichen Ergebnisse zu erzielen und ist 24 Stunden im Einsatz. 
        </p>
      </div>
      <div *ngIf="!isNextTo" class="hero-card" data-aos="fade-up-right" data-aos-delay="100" data-aos-duration="600">
        <h3>IMMER IM <br> EINSATZ</h3>
        <p>
          Der Algorithmus weiß wann Ihre Zielgruppe aktiv ist. Er versucht die bestmöglichen Ergebnisse zu erzielen und ist 24 Stunden im Einsatz. 
        </p>
      </div>
      <!--  -->
      <div *ngIf="!isNextTo" class="hero-card" data-aos="fade-up-left" data-aos-delay="100" data-aos-duration="600">
        <h3>BESONDERER <br> WETTBEWERBSVORTEIL</h3>
        <p>
          Sie werden dauerhaft von Ihrer Zielgruppe wahrgenommen. Hiermit schaffen Sie eine dauerhafte Präsenz und sichern sich einen großen Vorteil. 
        </p>
      </div>
      <div *ngIf="isNextTo" class="hero-card" data-aos="fade-up" data-aos-delay="500" data-aos-duration="600">
        <h3>BESONDERER <br> WETTBEWERBSVORTEIL</h3>
        <p>
          Sie werden dauerhaft von Ihrer Zielgruppe wahrgenommen. Hiermit schaffen Sie eine dauerhafte Präsenz und sichern sich einen großen Vorteil. 
        </p>
      </div>
    </div>
    <!-- <div class="hero-card-container">
      <div *ngIf="isNextTo" class="hero-card" data-aos="fade-up" data-aos-delay="500" data-aos-duration="600">
        <h3><span>+</span> NEUKUNDEN</h3>
        <p>
          Gemeinsam entwickeln wir eine <span>nachhaltige Marketingstrategie</span> <br>
          wodurch Sie konstant neue Kontakte generieren und sich nicht nur auf Empfehlungen und Kaltakquise verlassen müssen.
        </p>
      </div>
      <div *ngIf="!isNextTo" class="hero-card" data-aos="fade-up-left" data-aos-delay="100" data-aos-duration="600">
        <h3><span>+</span> NEUKUNDEN</h3>
        <p>
          Gemeinsam entwickeln wir eine <span>nachhaltige Marketingstrategie</span> <br>
          wodurch Sie konstant neue Kontakte generieren und sich nicht nur auf Empfehlungen und Kaltakquise verlassen müssen.
        </p>
      </div>
      <div *ngIf="isNextTo" class="hero-card" data-aos="fade-up" data-aos-delay="800" data-aos-duration="600">
        <h3><span>+</span> TOP-TALENTE</h3>
        <p>
          Mithilfe sozialer Medien sprechen wir Ihre Wunschmitarbeiter direkt an und <span>heben Sie von dem Wettbewerb ab.</span> <br>
          Wir präsentieren Sie auch bei Personen, die nicht aktiv auf der Suche sind.
        </p>
      </div>
      <div *ngIf="!isNextTo" class="hero-card" data-aos="fade-up-right" data-aos-delay="100" data-aos-duration="600">
        <h3><span>+</span> TOP-TALENTE</h3>
        <p>
          Mithilfe sozialer Medien sprechen wir Ihre Wunschmitarbeiter direkt an und <span>heben Sie von dem Wettbewerb ab.</span> <br>
          Wir präsentieren Sie auch bei Personen, die nicht aktiv auf der Suche sind.
        </p>
      </div>
      <div *ngIf="isNextTo" class="hero-card" data-aos="fade-up-left" data-aos-delay="100" data-aos-duration="600">
        <h3><span>+</span> SICHTBARKEIT<br><span>+</span> BEKANNTHEIT</h3>
        <p>
          Langfristig eine starke Marke mit <br>
          <span>maximaler Reichweite.</span> <br>
          Sie erhalten Sichtbarkeit auch außerhalb Ihres Netzwerkes.
        </p>
      </div>
      <div *ngIf="!isNextTo" class="hero-card" data-aos="fade-up" data-aos-delay="1100" data-aos-duration="600">
        <h3><span>+</span> SICHTBARKEIT<br><span>+</span> BEKANNTHEIT</h3>
        <p>
          Langfristig eine starke Marke mit <br>
          <span>maximaler Reichweite.</span> <br>
          Sie erhalten Sichtbarkeit auch außerhalb Ihres Netzwerkes.
        </p>
      </div>
    </div> -->
  </div>
</div>

