
<div class="container hero-container">
  <!-- <img id="heroBackground" src="../../assets/Background.png" alt=""> -->
  <div class="hero-wrapper" id="start">
    <div class="hero">
      <h1 class="hero_headline">Mehr <span id="hero-headline-span">Wachstum</span> durch digitales Marketing</h1>
      <p class="hero_text">
        Über Facebook, Instagram und LinkedIn regelmäßig neue Kunden und Mitarbeiter gewinnen
      </p>
      <a target="_blank">
        <button class="contact-button btnFirstDialog">Kostenloses Erstgespräch vereinbaren</button>
      </a>
    </div>
  </div>
</div>

