<div class="container impressum-container">
  <div class="wrapper impressum-wrapper">
    <h3>Datenschutzerklärung</h3>
    <div class="textbox">
      <p>
        Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst.
        Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen
        Datenschutzvorschriften sowie dieser Datenschutzerklärung
      </p>
      <p>
        Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich.
        Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder E-Mail-Adressen)
        erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne
         Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben.
      </p>
      <p>
        Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail)
        Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
      </p>
      <h4>
        Cookies
      </h4>
      <p>
        Die Internetseiten verwenden teilweise so genannte Cookies. Cookies richten auf Ihrem Rechner keinen Schaden
        an und enthalten keine Viren. Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen.
        Cookies sind kleine Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr Browser speichert.
        <br>
        Die meisten der von uns verwendeten Cookies sind so genannte „Session-Cookies“. Sie werden nach Ende Ihres Besuchs
        automatisch gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese löschen. Diese Cookies
        ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen.
        <br>
        Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies
        nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie
        das automatische Löschen der Cookies beim Schließen des Browser aktivieren. Bei der Deaktivierung von
        Cookies kann die Funktionalität dieser Website eingeschränkt sein.
      </p>
      <h4>
        Server-Log-Files
      </h4>
      <p>
        Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log Files,
        die Ihr Browser automatisch an uns übermittelt. Dies sind:
        <br>
        Browsertyp und Browserversion verwendetes Betriebssystem Referrer URL Hostname des zugreifenden Rechners
        Uhrzeit der Serveranfrage Diese Daten sind nicht bestimmten Personen zuordenbar.
        Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.
        Wir behalten uns vor, diese Daten nachträglich zu prüfen, wenn uns konkrete Anhaltspunkte
         für eine rechtswidrige Nutzung bekannt werden.
      </p>
      <h4>Kontaktformular</h4>
      <p>
        Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem Anfrageformular
        inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall
        von Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
      </p>
      <h4>
        Google Analytics
      </h4>
      <p>
        Diese Website nutzt Funktionen des Webanalysedienstes Google Analytics. Anbieter ist die Google Inc.,
        1600 Amphitheatre Parkway Mountain View, CA 94043, USA.
        <br>
        Google Analytics verwendet so genannte „Cookies“. Das sind Textdateien,
        die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen.
        Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden in
        der Regel an einen Server von Google in den USA übertragen und dort gespeichert.
        <br>
        Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics finden Sie in der
        Datenschutzerklärung von Google: https://support.google.com/analytics/answer/6004245?hl=de
      </p>
      <h4>Browser Plugin</h4>
      <p>
        Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern;
        wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen
        dieser Website vollumfänglich werden nutzen können. Sie können darüber hinaus die Erfassung der durch
        den Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse)
        an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem folgenden Link
        verfügbare Browser- Plugin herunterladen und installieren: https://tools.google.com/dlpage/gaoptout?hl=de
      </p>
      <h4>
        Widerspruch gegen Datenerfassung
      </h4>
      <p>
        Sie können die Erfassung Ihrer Daten durch Google Analytics verhindern, indem Sie auf folgenden Link klicken.
        Es wird ein Opt-Out-Cookie gesetzt, der die Erfassung Ihrer Daten bei zukünftigen Besuchen dieser Website verhindert:
         Google Analytics deaktivieren
      </p>
      <h4>
        Auftragsdatenverarbeitung
      </h4>
      <p>
        Wir haben mit Google einen Vertrag zur Auftragsdatenverarbeitung abgeschlossen und setzen die strengen
        Vorgaben der deutschen Datenschutzbehörden bei der Nutzung von Google Analytics vollständig um.
      </p>
      <h4>
        IP-Anonymisierung
      </h4>
      <p>
        Wir nutzen die Funktion „Aktivierung der IP-Anonymisierung“ auf dieser Webseite. Dadurch wird Ihre IP-Adresse
        von Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des
        Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse
        an einen Server von Google in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Website
        wird Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die
        Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene
        Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics von
        Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.
      </p>
      <h4>
        Demografische Merkmale bei Google Analytics
      </h4>
      <p>
        Diese Website nutzt die Funktion “demografische Merkmale” von Google Analytics.
        Dadurch können Berichte erstellt werden, die Aussagen zu Alter, Geschlecht und Interessen
        der Seitenbesucher enthalten. Diese Daten stammen aus interessenbezogener Werbung von Google sowie
        aus Besucherdaten von Drittanbietern. Diese Daten können keiner bestimmten Person zugeordnet werden.
        Sie können diese Funktion jederzeit über die Anzeigeneinstellungen in Ihrem Google-Konto deaktivieren
        oder die Erfassung Ihrer Daten durch Google Analytics wie im Punkt “Widerspruch gegen Datenerfassung”
        dargestellt generell untersagen.
      </p>
      <h4>
        Facebook-Plugins (Like-Button)
      </h4>
      <p>
        Auf unseren Seiten sind Plugins des sozialen Netzwerks Facebook, Anbieter Facebook Inc., 1 Hacker Way,
        Menlo Park, California 94025, USA, integriert. Die Facebook-Plugins erkennen Sie an dem Facebook-Logo
        oder dem „Like- Button“ („Gefällt mir“) auf unserer Seite. Eine Übersicht über die Facebook-Plugins
        finden Sie hier: http://developers.facebook.com/docs/plugins/.
      </p>
      <p>
        Wenn Sie unsere Seiten besuchen, wird über das Plugin eine direkte Verbindung zwischen Ihrem Browser
        und dem Facebook-Server hergestellt. Facebook erhält dadurch die Information, dass Sie mit Ihrer
        IP-Adresse unsere Seite besucht haben. Wenn Sie den Facebook „Like-Button“ anklicken während Sie in
        Ihrem Facebook- Account eingeloggt sind, können Sie die Inhalte unserer Seiten auf Ihrem Facebook-Profil verlinken.
        Dadurch kann Facebook den Besuch unserer Seiten Ihrem Benutzerkonto zuordnen.
        Wir weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der übermittelten Daten
        sowie deren Nutzung durch Facebook erhalten. Weitere Informationen hierzu finden Sie in der Datenschutzerklärung
        von Facebook unter http://de- de.facebook.com/policy.php.
        <br>
        Wenn Sie nicht wünschen, dass Facebook den Besuch unserer Seiten Ihrem Facebook-Nutzerkonto zuordnen kann,
        loggen Sie sich bitte aus Ihrem Facebook- Benutzerkonto aus.
      </p>
      <h4>
        Instagram
      </h4>
      <p>
        Auf unseren Seiten sind Funktionen des Dienstes Instagram eingebunden. Diese Funktionen werden angeboten durch die Instagram Inc.,
        1601 Willow Road, Menlo Park, CA, 94025, USA integriert. Wenn Sie in Ihrem Instagram-Account eingeloggt sind können Sie durch
        Anklicken des Instagram-Buttons die Inhalte unserer Seiten mit Ihrem Instagram-Profil verlinken. Dadurch kann Instagram den
        Besuch unserer Seiten Ihrem Benutzerkonto zuordnen. Wir weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis
        vom Inhalt der übermittelten Daten sowie deren Nutzung durch Instagram erhalten.
        <br>
        Weitere Informationen hierzu finden Sie in der Datenschutzerklärung von Instagram: http://instagram.com/about/legal/privacy/
      </p>
      <h4>
        Widerspruch Werbe-Mails
      </h4>
      <p>
        Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung von nicht ausdrücklich
        angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen. Die Betreiber der Seiten behalten
        sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen,
        etwa durch Spam-E-Mails, vor.
      </p>
      <p>
        Hosting und Content Delivery Networks (CDN)Externes Hosting:
        Diese Website wird bei einem externen Dienstleister gehostet (Hoster). Die personenbezogenen Daten,
        die auf dieser Website erfasst werden, werden auf den Servern des Hosters gespeichert.
        Hierbei kann es sich v. a. um IP-Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten,
        Vertragsdaten, Kontaktdaten, Namen, Websitezugriffe und sonstige Daten, die über eine Website generiert werden, handeln.
      </p>
      <p>
        Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserfüllung gegenüber unseren potenziellen und bestehenden Kunden
        (Art. 6 Abs. 1 lit. b DSGVO) und im Interesse einer sicheren, schnellen und effizienten Bereitstellung unseres
        Online-Angebots durch einen professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO).
      </p>
      <p>
        Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies zur Erfüllung seiner Leistungspflichten erforderlich
        ist und unsere Weisungen in Bezug auf diese Daten befolgen.
      </p>
      <p>
        Wir setzen folgenden Hoster ein:
      </p>
      <p>
        united-domains AG <br>
        Gautinger Straße 10 <br>
        82319 Starnberg <br>
        Deutschland <br>
      </p>
      <p>
        Wir haben einen Vertrag über Auftragsverarbeitung (AVV)
        zur Nutzung des oben genannten Dienstes geschlossen.
        Hierbei handelt es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag,
        der gewährleistet, dass dieser die personenbezogenen Daten unserer Websitebesucher nur nach unseren
      </p>
      <p>
        Weisungen und unter Einhaltung der DSGVO verarbeitet.
      </p>
      <p>
        Quelle: https://www.e-recht24.de
      </p>
    </div>
  </div>
</div>
