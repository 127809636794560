<div class="container about-container">
  <div class="wrapper about-wrapper">
    <div class="headline">
      <h2 class="headline_left headline_white">Über Kevin Dümke</h2>
    </div>
    <div class="content-wrapper about-content-wrapper">
      <div class="left" data-aos="zoom-in">
        <div class="about-image">

        </div>
      </div>
      <div class="right">
        <p>
          Kevin Dümke ist seit vielen Jahren im Bereich Performance-Marketing tätig. <br>
          Nach dem Abschluss zum staatlich gepr. Betriebswirt hat er sein Wissen durch verschiedene Projekte
          vertieft und einen breiten Erfahrungsschatz aufgebaut. Regelmäßige Weiterbildungen sichern seinen
          Kunden langfristig messbar gute Ergebnisse. <br>
          Mit diesem Wissen unterstützt er mittelständische Unternehmen beim Aufbau von digitalen Marketing- und Vertriebsstrategien.
        </p>
      </div>
    </div>
  </div>
</div>
