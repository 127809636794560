<div class="campaign-container">
  <div class="wrapper campaign-wrapper">
    <div>
      <h2 class="headline">Besondere Art der Kampagnenbetreuung</h2>
    </div>
    <div class="content-wrapper">
      <div class="left" data-aos="fade-right">
        <div class="left-content-wrapper">
          <div class="image social-media-icons"></div>
          <div class="image theme-image"></div>
        </div>
      </div>
      <article class="right">
        <p>
          Viele Unternehmen unterschätzen die Möglichkeiten von bezahlten Werbeanzeigen auf Facebook,
          Instagram, TikTok und LinkedIn. Zum Start unserer Zusammenarbeit analysieren wir Ihr Unternehmen,
          die Zielgruppe und den Wettbewerb. Gemeinsam entwickeln wir eine <br>
          <span>individuell angepasste Strategie</span><br>
          damit die Marke langfristig ausgebaut werden kann. Danach startet eine ausführliche Testphase.
          In dieser Zeit werden unterschiedliche Anzeigen, Zielgruppen, Ansprachen und Formate getestet,
          um den vielversprechendsten Ansatz zu finden und darauf aufzubauen. <br>
          Hierdurch wissen wir, in welche Richtung Ihre Kampagnen gehen müssen. Wir nutzen das gewonnene Wissen, um das
          Budget dort zu investieren, wo die <br>
          <span>besten Ergebnisse</span><br>
          erzielt werden können. <br><br><br>
          Entgegen der meisten Behauptungen gibt es im Performance Marketing nicht den einen richtigen Weg,
          die Tests gehen immer weiter und es muss an jeder Stelle optimiert werden.
          Jedoch können wir durch datengetriebene Analysen immer bessere Ergebnisse erzielen.
        </p>
      </article>
    </div>
  </div>
</div>

