import { Component } from '@angular/core';
import AOS from "aos";

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.sass']
})
export class AboutComponent {

  constructor() {
    AOS.init();
  }
}
