import { Component, Input } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { CookieService } from './cookie-banner/cookies.service';
declare let gtag:Function;
declare let fbq:Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent {
  title = 'Dümke Marketing';

  faceBookPixelScript = `
  !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '951881076114963');
  fbq('track', 'PageView');
  `

  facebookPixelScript = document.createElement('script');


  constructor(private cookieService: CookieService) {
  }

  ngOnInit() {

    this.facebookPixelScript.innerText = this.faceBookPixelScript
    const accepted = this.cookieService.getCookie('cookies_accepted');

    if(accepted) {
      document.head.appendChild(this.facebookPixelScript);
    }
  }

  onCookiesDecisionMade(event: boolean) {
    if(event === true) {
      document.head.appendChild(this.facebookPixelScript);
    }
  }

}
