<div class="container footer-container">
  <div class="wrapper footer-wrapper">
    <div class="content-wrapper footer-content-wrapper">
      <div class="left">
        <!-- <div class="brandImg"></div>
          <hr> -->
        <div class="contactInfo-wrapper" id="contact">
          <p><i class="bi bi-telephone-fill"></i><a href="tel: 0173 / 8593736">0173 / 8593736</a></p>
          <p><i class="bi bi-envelope-at-fill"></i><a href="mailto: kevin.duemke@duemke-marketing.de">kevin.duemke@duemke-marketing.de</a></p>
          <p><i class="bi bi-building-fill-add"></i>Schießstandweg 17 / 04838 Doberschütz</p>
        </div>
      </div>
      <div class="right">
        <h3>Rechtliches</h3>
        <p><a routerLink="/impressum" target="_blank">Impressum</a></p>
        <p><a routerLink="/datenschutz" target="_blank">Datenschutz</a></p>
      </div>
    </div>
  </div>
</div>
