<div class="container testimonials-container">
  <div class="wrapper content-wrapper_flexcol">
    <div class="headline">
      <h2 class="headline_left">
        Unternehmen die bereits Erfolge erzielen
      </h2>
    </div>
    <div class="content-wrapper testimonial-wrapper">
      <div class="testimonial-image" id="testihoening">
      </div>
      <div class="testimonial-text-wrapper">
        <!-- <div class="testimonial-headline">
          <h4>HÖNING GmbH für Fenster und Türen</h4>
          <p>www.höning.de</p>
        </div> -->
        <div class="testimonial-text">
          <p>
            Dank unserer Kooperation mit Kevin Dümke konnten wir erfolgreich neue Mitarbeiter für unsere offenen Stellen als Berufskraftfahrer und Web-Entwickler gewinnen.
            Die Recruiting-Kampagnen und das effektive Zielgruppen-Targeting haben die Bewerberzahl erheblich gesteigert und die Qualität der Bewerber deutlich verbessert.
            Als Mittelständisches Unternehmen stehen wir vor der großen Herausforderung geeignete Arbeitskräfte zu finden. Herr Dümke demonstriert beeindruckend, wie diese
            Herausforderung gemeistert werden kann. Wir freuen uns auf eine fortgesetzte erfolgreiche Zusammenarbeit und danken Kevin Dümke für die hervorragende Arbeit.
          </p>
          <p class="testimonial-person">- Steffen Roth (Prokurist Prozessoptimierung)</p>
        </div>
        <div class="testimonial-summary">
          <h4>Ergebnisse</h4>
          <p><span class="testimonial-highlight">Berufskraftfahrer</span> <span>|</span> 4 Wochen Laufzeit <span>|</span> 37 qual. Bewerbungen <span>|</span> 1 Einstellung I Budget: 796€</p>
          <p><span class="testimonial-highlight">Web-Entwickler</span> <span>|</span> 2 Wochen Laufzeit <span>|</span> 5 qual. Bewerbungen <span>|</span> 1 Einstellung I Budget: 453€</p>
        </div>
      </div>
    </div>
    <hr>
    <div class="content-wrapper testimonial-wrapper">
      <div class="testimonial-image" id="testikoch">
      </div>
      <div class="testimonial-text-wrapper">
        <!-- <div class="testimonial-headline">
          <h4>Koch Energielösungen GmbH</h4>
          <p>wwww.kochenergieloesungen.de</p>
        </div> -->
        <div class="testimonial-text">
          <p>
            Wir arbeiten seit ca. 1 Jahr erfolgreich mit Kevin Dümke im Bereich Lead-Generierung zusammen.
            <br>
            <br>
            Besonders hervorheben muss ich die freundliche, kommunikative Art von Kevin, die Transparenz und die Ehrlichkeit. Er bleibt hartnäckig dran, gerade wenn wir auch mal nachlässig in der Zuarbeit sind. Die Auswertungen sind verständlich und umfangreich, aber nicht zu detailliert.
            Was ich zusätzlich loben muss, ist, dass er auf unsere Anfragen zu themenabweichenden Formaten ehrlich ablehnt und diese nicht auf Krampf versucht, zu seinem geldlichen Vorteil umzusetzen.
          </p>
          <p class="testimonial-person">- Christian Heinze (Stellv. Geschäftsführer und Leitung Innendienst)</p>
        </div>
        <div class="testimonial-summary">
          <h4>Ergebnisse</h4>
          <p><span class="testimonial-highlight">Selbstständige Vetriebspartner</span> <span>|</span> Pro Monat rund 40 qual. Bewerbungen <span>|</span> Budget: 600€</p>
          <p><span class="testimonial-highlight">Neukundengewinnung</span> <span>|</span> 4 Wochen Laufzeit <span>|</span> 8 qual. Anfragen</p>
        </div>
      </div>
    </div>
  </div>
</div>
