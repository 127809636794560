import { Component, OnInit } from '@angular/core';
import AOS from 'aos';

@Component({
  selector: 'app-like-to-work',
  templateUrl: './like-to-work.component.html',
  styleUrls: ['./like-to-work.component.sass']
})
export class LikeToWorkComponent {

constructor() {
  AOS.init();
}

}
