<div class="container trusted-companies-container pb-40">
  <div class="wrapper">
    <h3 class="black-heading">UNTERNEHMEN DIE UNS VERTRAUEN</h3>
    <div class="company-logos wrapper">
      <div class="company-logo company-logo_1"></div>
      <div class="company-logo company-logo_2"></div>
      <div class="company-logo company-logo_3"></div>
      <div class="company-logo company-logo_4"></div>
    </div>
  </div>
</div>
