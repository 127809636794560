<div class="container likeToWork-container">
  <div class="wrapper likeToWork-wrapper">
    <div>
      <h2 class="headline">Interesse geweckt?</h2>
    </div>
    <div class="content-wrapper likeToWork-content-wrapper">
      <div class="stepCard" data-aos="zoom-out-up" data-aos-delay="100" data-aos-easing="ease-in-sine" data-aos-duration="300">
        <h3 class="stepCard_header">Erstgespräch vereinbaren</h3>
        <div class="stepCard_text_wrapper">
          <p class="stepCard_text">
            Damit wir eine hervorragende Dienstleistung sicherstellen können,
            arbeiten wir nur mit einer bestimmten Anzahl an Unternehmen zusammen
          </p>
        </div>
      </div>
      <div class="stepCard-Arrow" data-aos="flip-up" data-aos-delay="200" data-aos-easing="ease-in-sine" data-aos-duration="300">
        <i class="bi bi-arrow-right"></i>
      </div>
      <div class="stepCard" data-aos="zoom-out-up" data-aos-delay="300" data-aos-easing="ease-in-sine" data-aos-duration="300">
        <h3 class="stepCard_header">Telefonisches Vorgespräch</h3>
        <div class="stepCard_text_wrapper">
          <p class="stepCard_text">
            Wir klären in einem kurzen Telefongespräch an welchem Punkt Ihr Unternehmen steht,
            welche Potenziale bestehen und ob eine Zusammenarbeit passen könnte.
          </p>
        </div>
      </div>
      <div class="stepCard-Arrow" data-aos="flip-up" data-aos-delay="400" data-aos-easing="ease-in-sine" data-aos-duration="300">
        <i class="bi bi-arrow-right"></i>
      </div>
      <div class="stepCard" data-aos="zoom-out-up" data-aos-delay="500" data-aos-easing="ease-in-sine" data-aos-duration="300">
        <h3 class="stepCard_header">Strategiegespräch</h3>
        <div class="stepCard_text_wrapper">
          <p class="stepCard_text">
            Nachdem wir einen Eindruck über Ihr Unternehmen bekommen haben, führen wir ggf.
            ein ausführliches Strategiegespräch in dem wir einen konkreten Plan für Ihre digitale Sichtbarkeit entwerfen.
          </p>
        </div>
      </div>
    </div>
    <button class="contact-button btnFirstDialog">Kostenloses Erstgespräch vereinbaren</button>
  </div>
</div>

