import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    const brandImg = document.getElementById("brandImgTop");
    const contactButton = document.getElementById("contactBtnTop");
    brandImg.addEventListener('click', () => this.handleClickImg())
    contactButton.addEventListener('click', () => this.handleClickButton())
  }

  private handleClickImg() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  public handleClickButton() {
    const body = document.getElementsByTagName('body');
    window.scrollTo({
      top: body[0].clientHeight,
      behavior: 'smooth'
    });
  }

  // private handleClickContact() {
  // window.scrollTo({
  //     top: document.innerHeight,
  //     behavior: 'smooth'
  //   });
  // }

}
