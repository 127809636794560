import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header/header.component';
import { HeroSectionComponent } from './main-page/hero-section/hero-section.component';
import { CampaignPartComponent } from './main-page/campaign-part/campaign-part.component';
import { TrustedCompaniesComponent } from './main-page/trusted-companies/trusted-companies.component';
import { ReasonsCaruselComponent } from './main-page/reasons-carusel/reasons-carusel.component';
import { WorkTogetherComponent } from './main-page/work-together/work-together/work-together.component';
import { AboutComponent } from './main-page/about/about.component';
import { FooterComponent } from './footer/footer.component';
import { LikeToWorkComponent } from './main-page/like-to-work/like-to-work.component';
import { DataProtectionComponent } from './data-protection/data-protection.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { MainPageComponent } from './main-page/main-page.component';
import { CookieBannerComponent } from './cookie-banner/cookie-banner.component';
import { ProblemSolutionComponent } from './main-page/problem-solution/problem-solution.component';
import { HeroCardsComponent } from './main-page/hero-cards/hero-cards.component';
import { TestimonialsComponent } from './main-page/testimonials/testimonials.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HeroSectionComponent,
    CampaignPartComponent,
    TrustedCompaniesComponent,
    ReasonsCaruselComponent,
    WorkTogetherComponent,
    AboutComponent,
    FooterComponent,
    LikeToWorkComponent,
    DataProtectionComponent,
    ImpressumComponent,
    MainPageComponent,
    CookieBannerComponent,
    ProblemSolutionComponent,
    HeroCardsComponent,
    TestimonialsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
