import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-hero-section',
  templateUrl: './hero-section.component.html',
  styleUrls: ['./hero-section.component.sass']
})
export class HeroSectionComponent{


}
