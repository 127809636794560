import { Component } from '@angular/core';

@Component({
  selector: 'app-trusted-companies',
  templateUrl: './trusted-companies.component.html',
  styleUrls: ['./trusted-companies.component.sass']
})
export class TrustedCompaniesComponent {

}
