
<app-hero-section style="height: calc(100% + 115px)"></app-hero-section>
<app-trusted-companies></app-trusted-companies>
<app-problem-solution></app-problem-solution>
<!-- <app-reasons-carusel></app-reasons-carusel> -->
<!-- <app-campaign-part></app-campaign-part> -->
<app-hero-cards></app-hero-cards>
<app-testimonials></app-testimonials>
<!-- <app-work-together></app-work-together> -->
<app-campaign-part></app-campaign-part>
<app-about></app-about>
<app-like-to-work></app-like-to-work>
