import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.sass']
})
export class MainPageComponent implements OnInit {

  ngOnInit(): void {

    const firstContactButtons = document.getElementsByClassName('btnFirstDialog');

    Array.from(firstContactButtons).forEach(button => {
      button.addEventListener('click', () => {
        window.open('https://calendly.com/kevin-duemke/erstgesprach?month=2023-08', '_blank');
      })
    })


  }

}
