<div class="container problem-container">
  <div class="wrapper problem-wrapper">
    <div>
      <h3 class="headline headline_center headline_small pb-40">Davon berichten uns mittelständische Unternehmen</h3>
    </div>
    <div class="content-wrapper problem-content-wrapper-mobile">

      <div class="problem-image-wrapper">
        <div id="problem-image"></div>
      </div>
        <div class="problem-content-wrapper">
          <ul style="list-style: none;">
            <li class="list-custom-icon" data-aos="fade-up" data-aos-duration="500" data-aos-delay="100">
              <p><span class="list-icon-check"><i class="bi bi-exclamation-lg"></i></span>Anfragen gehen zurück - Sie müssen um jeden Auftrag bangen.</p>
            </li>
            <li class="list-custom-icon" data-aos="fade-up" data-aos-duration="500" data-aos-delay="300">
              <p><span class="list-icon-check"><i class="bi bi-exclamation-lg"></i></span>Interessenten holen sich viele Angebote - Sie haben keine exklusiven Leads zur Verfügung.</p>
            </li>
            <li class="list-custom-icon" data-aos="fade-up" data-aos-duration="500" data-aos-delay="500">
              <p><span class="list-icon-check"><i class="bi bi-exclamation-lg"></i></span>Klassische Werbung funktioniert nicht und kostet viel Geld.</p>
            </li>
            <li class="list-custom-icon" data-aos="fade-up" data-aos-duration="500" data-aos-delay="700">
              <p><span class="list-icon-check"><i class="bi bi-exclamation-lg"></i></span>Stellenanzeigen in Portalen, auf der Homepage und in der Zeitung zeigen keinerlei Wirkung.</p>
            </li>
          </ul>
          <a target="_blank">
            <button class="contact-button btnFirstDialog">Jetzt Neukunden & Mitarbeiter gewinnen</button>
          </a>
        </div>
      <!-- <div>
        <h2 class="headline headline_white">Ihre Lösung -  digitales Marketing!</h2>
      </div>
      <div class="content-wrapper">
        <ul style="list-style: none;">
          <li class="list-custom-icon">
            <h4 style="position: relative;"><span class="list-icon-check"><i class="bi bi-check"></i></span>Genaue Zielgruppenansprache</h4>
            <p>
            Über passende Inhalte sprechen wir Ihre Zielgruppe direkt an. Mit verschiedenen Formaten sorgen wir für interessante Anzeigen.
            </p>
          </li>
          <li class="list-custom-icon">
            <h4 style="position: relative;"><span class="list-icon-check"><i class="bi bi-check"></i></span>Immer im Einsatz</h4>
            <p>
            Der Algorithmus weiß wann Ihre Zielgruppe aktiv ist. Er versucht die bestmöglichen Ergebnisse zu erzielen und ist 24 Stunden im Einsatz.
            </p>
          </li>
          <li class="list-custom-icon">
            <h4 style="position: relative;"><span class="list-icon-check"><i class="bi bi-check"></i></span>Wettbewerbsvorteil</h4>
            <p>
            Sie werden dauerhaft von Ihrer Zielgruppe wahrgenommen. Hiermit schaffen Sie eine dauerhafte Präsenz und sichern sich einen großen Vorteil.
            </p>
          </li>
        </ul>
      </div> -->
    </div>
  </div>
</div>
