import { Component } from '@angular/core';

@Component({
  selector: 'app-campaign-part',
  templateUrl: './campaign-part.component.html',
  styleUrls: ['./campaign-part.component.sass']
})
export class CampaignPartComponent {

}
