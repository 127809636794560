import { Component, OnInit } from '@angular/core';
import AOS from 'aos';

@Component({
  selector: 'app-hero-cards',
  templateUrl: './hero-cards.component.html',
  styleUrls: ['./hero-cards.component.scss']
})
export class HeroCardsComponent implements OnInit {

  isNextTo: boolean;


  constructor() {
    AOS.init();
  }

  ngOnInit(): void {

    window.addEventListener('resize', this.onResize);

    if(window.innerWidth >= 940) {
      this.isNextTo = true;
    } else {
      this.isNextTo = false;
    }
  }

  onResize(event) {
    if(event.target.innerWidth <= 940) {
      this.isNextTo = false;
    } else {
      this.isNextTo = true;
    }
  }

}
